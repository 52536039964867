/// <reference types="." />
import PopupController from './PopupController';
import { ConstantPopupData } from './PopupData';

export abstract class ConstantDataTransformer {
    public abstract transform<T>(data: ConstantPopupData<T>): ConstantPopupData<T>;
}

export function transformData<T>(
    transformers: ConstantDataTransformer[],
    data: ConstantPopupData<T>,
): ConstantPopupData<T> {
    return transformers.reduce<ConstantPopupData<T>>(
        (previousValue: ConstantPopupData<T>, currentValue: ConstantDataTransformer) => {
            return currentValue.transform(previousValue);
        },
        data,
    );
}

export class DisposableTransformer extends ConstantDataTransformer {
    public constructor(
        private readonly deleteTimeout = 0,
        private readonly triggerOnShow = false,
    ) {
        super();
    }

    public transform<T>(data: ConstantPopupData<T>) {
        const prevOnDismiss = data.onDismiss;
        const popupsManager = window.popupsManager;

        data.onDismiss = (controller: PopupController<T>) => {
            setTimeout(() => {
                popupsManager.delete(controller.getKey());
            }, this.deleteTimeout);
            if (prevOnDismiss) {
                prevOnDismiss(controller);
            }
        };

        if (this.triggerOnShow) {
            const prevOnShow = data.onShow;
            data.onShow = (controller: PopupController<T>) => {
                setTimeout(() => {
                    popupsManager.delete(controller.getKey());
                }, this.deleteTimeout);
                if (prevOnShow) {
                    prevOnShow(controller);
                }
            };
        }

        return data;
    }
}
