import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Spr_user_message_ot } from '@alcs/beans';
import { createDefaultI18n, useI18n } from '@alcs/i18n';

import { showConfirmPopup } from './popup-widgets/ConfirmPopup';
import { GwtContext, useGwt } from './GwtContext';
import ChangeListener from '../utils/ChangeListener';

export const PopupErrorsContext = React.createContext<{
    errors: Spr_user_message_ot[];
    clearErrors: (...indexes: number[]) => void;
}>({
    errors: [],
    clearErrors: () => {
        // empty function
    },
});

const defaultI18n = createDefaultI18n('components.GwtPopupContext', {
    unsavedChanges: 'You have unsaved changes. Do you want to discard them?',
    warning: 'Warning',
    defaultErrorMessage: 'A system error has occured. Contact your system administrator',
});

export type GwtPopupContextProps = PropsWithChildren<{
    dismiss: () => void;
}>;

export const GwtPopupContext = ({ dismiss, children }: GwtPopupContextProps) => {
    const currentGwtContext = useGwt();
    const i18n = useI18n(defaultI18n);

    const [errors, setErrors] = useState<Spr_user_message_ot[]>([]);

    const changeListener = useMemo(() => new ChangeListener<unknown>(), []);

    const closeForm = useCallback(async () => {
        if (!changeListener.isModified() || (await showConfirmPopup(i18n.warning, i18n.unsavedChanges))) {
            dismiss();
        }
    }, [changeListener, dismiss, i18n]);

    const onFailure = useCallback(
        (_code: number, error: null | { messages: Spr_user_message_ot[] }) => {
            if (error && Array.isArray(error.messages)) {
                const messages = error.messages.map(({ default_text, severity, ...other }) => ({
                    ...other,
                    default_text: default_text ?? i18n.defaultErrorMessage,
                    severity: severity ?? 'E',
                }));

                setErrors(messages);
            } else {
                setErrors([
                    {
                        code: 'system_error',
                        default_text: i18n.defaultErrorMessage,
                        severity: 'E',
                    } as Spr_user_message_ot,
                ]);
            }

            currentGwtContext.callback.onSuccess();
        },
        [currentGwtContext.callback, i18n],
    );

    const startCall = useCallback(() => {
        setErrors([]);
        currentGwtContext.callback.startCall();
    }, [currentGwtContext.callback]);

    const clearErrors = useCallback((...indexes: number[]) => {
        setErrors(prevErrors => prevErrors.filter((_, index) => !indexes.includes(index)));
    }, []);

    const callbackObject = useMemo(
        () => ({
            ...currentGwtContext.callback,
            startCall,
            onFailure,
        }),
        [currentGwtContext.callback, onFailure, startCall],
    );

    return (
        <GwtContext.Provider
            value={{
                ...currentGwtContext,
                isInDialog: () => true,
                closeForm,
                initialFormValues: changeListener.initialFormValues as <T>(values: T) => T,
                modifiedFormValues: changeListener.modifiedFormValues,
                callback: callbackObject,
            }}
        >
            <PopupErrorsContext.Provider value={{ errors, clearErrors }}>{children}</PopupErrorsContext.Provider>
        </GwtContext.Provider>
    );
};
