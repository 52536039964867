import React from 'react';
import { Severity } from '@alcs/enums';
import { Alert, AlertColor, styled, Typography } from '@mui/material';

import { Spr_user_message_ot } from '@alcs/beans/src';

import { ErrorMessage } from './ErrorMessage';
import { formatError } from '../utils/TextUtils';

type UserMessagesProps = {
    messages: Spr_user_message_ot[];
    onClose?: (indexes: number[]) => void;
};

const severities = Object.values(Severity);

const combineMessagesBySeverity = (
    messages: Spr_user_message_ot[],
    severity: Severity,
    formatter: (message: Spr_user_message_ot) => string,
): [message: Spr_user_message_ot, messagesIndexes: number[]] => {
    const message = { severity, default_text: '' } as Spr_user_message_ot;
    const messagesIndexes: number[] = [];
    const messagesTexts: string[] = [];

    messages.forEach((currentMessage, index) => {
        if (currentMessage.severity === severity) {
            messagesIndexes.push(index);
            messagesTexts.push(formatter(currentMessage));
        }
    });

    message.default_text = messagesTexts.join('\n');
    return [message, messagesIndexes];
};

type MessageFormatter = (message: Spr_user_message_ot) => string;

const formatters: Partial<Record<Severity, MessageFormatter>> = {
    E: formatError,
};

const alertComponents: Partial<Record<Severity, SeverityAlert>> = {
    E: ErrorMessage,
};

type SeverityAlertProps = {
    onClose: () => void;
    message: string;
    severity: AlertColor;
};

type SeverityAlert = React.ComponentType<SeverityAlertProps>;

const DefaultAlert = ({ message, ...props }: SeverityAlertProps) => (
    <Alert {...props}>
        <Typography>{message}</Typography>
    </Alert>
);
const defaultFormatter = (message: Spr_user_message_ot) => message.default_text;

const sprSeverityToMuiSeverity: Record<Severity, AlertColor> = {
    W: 'warning',
    I: 'info',
    E: 'error',
    S: 'success',
};

const UserMessagesContainer = styled('div', {
    label: 'UserMessagesContainer',
})({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    whiteSpace: 'pre-line',
});

export const UserMessages = ({ messages, onClose }: UserMessagesProps) => {
    if (messages.length === 0) {
        return null;
    }

    return (
        <UserMessagesContainer>
            {severities.map((severity, index) => {
                const [message, messagesIndexes] = combineMessagesBySeverity(
                    messages,
                    severity,
                    formatters[severity] ?? defaultFormatter,
                );

                if (message.default_text.length === 0) {
                    return null;
                }

                const Component = alertComponents[severity] ?? DefaultAlert;

                return (
                    <Component
                        key={index}
                        message={message.default_text}
                        onClose={() => onClose?.(messagesIndexes)}
                        severity={sprSeverityToMuiSeverity[severity]}
                    />
                );
            })}
        </UserMessagesContainer>
    );
};
