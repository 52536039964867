import React from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { FormHelpers, useFormContext, useFormMeta, useSubmitAction } from '@reactive-forms/core';

import Button, { PrivilegedButtonProps } from './Button';
import { ErrorTooltip } from './MaterialTheme';

const defaultI18n = createDefaultI18n('components.AlsSubmitButton', {
    errorMessage: 'Form cannot be submitted, because there are errors',
});

export type AlsSubmitButtonProps<T extends object> = PrivilegedButtonProps & {
    onClick?: never;
    submitAction?: (values: T, helpers: FormHelpers<T>) => void;
    enabledWhenSubmitting?: boolean;
    customErrorMessage?: string;
};

export const AlsSubmitButton = <T extends object>({
    submitAction,
    disabled,
    enabledWhenSubmitting,
    customErrorMessage,
    error: specifiedError,
    ...other
}: AlsSubmitButtonProps<T>) => {
    const i18n = useI18n(defaultI18n);
    const {
        formMeta: { paths },
    } = useFormContext();
    const isSubmitting = useFormMeta(paths.isSubmitting);
    const onClick = useSubmitAction(submitAction);

    const isValid = useFormMeta(paths.isValid);
    const submitCount: number = useFormMeta(paths.submitCount);

    const hasError = (!isValid && !isSubmitting && submitCount > 0) || customErrorMessage != undefined;

    return (
        <ErrorTooltip arrow title={(hasError && (customErrorMessage ? customErrorMessage : i18n.errorMessage)) || ''}>
            <Button
                {...(other as PrivilegedButtonProps)}
                disabled={disabled || (!enabledWhenSubmitting && isSubmitting)}
                onClick={onClick}
                error={specifiedError ?? hasError}
            />
        </ErrorTooltip>
    );
};
