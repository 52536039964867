import React from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { Alert, AlertTitle, Link } from '@mui/material';

const defaultI18n = createDefaultI18n('components.UnexpectedExceptionFallback', {
    title: 'System error',
    body: 'A system error has occurred. Please contact your support team for assurance',
});

export const UnexpectedExceptionFallback = () => {
    const i18n = useI18n(defaultI18n);

    return (
        <Alert severity="error">
            <AlertTitle>{i18n.title}</AlertTitle>
            {i18n.body} - <Link href="mailto:helpdesk@alcs.eu">helpdesk@alcs.eu</Link>
        </Alert>
    );
};
