/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 */
import React, { forwardRef, RefAttributes } from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material';

import { showConfirmPopup } from './popup-widgets/ConfirmPopup';
import { useFunctionPrivileges } from './FormPrivilegesContext';
import { AlsMuiButton } from './MaterialTheme';

/**
 * @extends ButtonProps
 * @property {?string} name - name to get function privilege and apply it
 * // TODO
 */
export type PrivilegedButtonProps = MuiButtonProps &
    ({ confirm?: false } | { confirm: boolean; confirmTitle: string; confirmText: string }) & {
        name?: string;
        error?: boolean;
    };

/**
 * Button component wraps Material UI button and adds privileges logic and optional confirmation.
 * @component
 */
const Button: React.ForwardRefExoticComponent<PrivilegedButtonProps & RefAttributes<HTMLButtonElement>> = forwardRef(
    (props, ref) => {
        const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { onClick } = props;
            if (!onClick) {
                return;
            }
            if (props.confirm) {
                const { confirmTitle, confirmText } = props;
                if (await showConfirmPopup(confirmTitle, confirmText)) {
                    onClick(e);
                }
            } else {
                onClick(e);
            }
        };

        const {
            // eslint-disable-next-line
            confirm,
            // eslint-disable-next-line
            confirmText,
            // eslint-disable-next-line
            confirmTitle,
            disabled,
            error,
            name,
            ...otherProps
            // eslint-disable-next-line
        } = props as any;

        const { visible, enabled } = useFunctionPrivileges(name);

        return visible ? (
            <AlsMuiButton
                {...otherProps}
                ref={ref}
                name={name}
                disabled={(!enabled && !!name) || !!disabled}
                onClick={handleClick}
                hasError={!!error}
            />
        ) : null;
    },
) as React.ForwardRefExoticComponent<PrivilegedButtonProps>;

export default Button;
