import React, { useContext } from 'react';

import { PopupErrorsContext } from '../GwtPopupContext';
import { UserMessages } from '../UserMessages';

export const PopupMessages = () => {
    const { errors, clearErrors } = useContext(PopupErrorsContext);

    return <UserMessages messages={errors} onClose={indexes => clearErrors(...indexes)} />;
};
