import React from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { Alert, AlertColor, Link, styled, Typography } from '@mui/material';

const defaultI18n = createDefaultI18n('components.ErrorMessage', {
    linkLabel: 'Please contact support team for assistance',
});

export type ErrorMessageProps = {
    message: string;
    severity: AlertColor;
    onClose: () => void;
};

const MessageFooter = styled('div', {
    label: 'MessageFooter',
})({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '4px',
});

export const ErrorMessage = ({ message, severity, onClose }: ErrorMessageProps) => {
    const i18n = useI18n(defaultI18n);

    return (
        <Alert sx={{ marginBottom: 1 }} severity={severity} variant="standard" onClose={onClose}>
            <Typography>{message}</Typography>
            <MessageFooter>
                <Typography component="p" variant="subtitle2">
                    {i18n.linkLabel}
                </Typography>
                <Link href={`mailto:helpdesk@alcs.eu?subject=${message}`} variant="subtitle2">
                    helpdesk@alcs.eu
                </Link>
            </MessageFooter>
        </Alert>
    );
};
