import { Link } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
        color: alpha(theme.palette.text.secondary, 0.8),
    },
}));
