import { useMemo } from 'react';
import { IServiceCallback } from '@alcs/gwt';

import { useGwt } from '../components/GwtContext';

interface IServiceConstructor<T> {
    new (callback: IServiceCallback): T;
}

export const useService = <T>(serviceConstructor: IServiceConstructor<T>, serviceCallback?: IServiceCallback): T => {
    const { callback: defaultServiceCallback } = useGwt();

    const instance = useMemo(
        () => new serviceConstructor(serviceCallback ?? defaultServiceCallback),
        [serviceConstructor, serviceCallback, defaultServiceCallback],
    );

    return instance;
};
