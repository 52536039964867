import React, { PropsWithChildren } from 'react';
import { CircularProgress, Icon, styled, Typography } from '@mui/material';
import Head from 'next/head';
import NextLink from 'next/link';

import { ErrorBoundary } from './ErrorBoundary';
import { StyledLink } from './StyledLink';
import { UnexpectedExceptionFallback } from './UnexpectedExceptionFallback';

export type PageContainerProps = PropsWithChildren<{
    title: string;
    backHref: string;
    backTitle: string;
    className?: string;
    loaded?: boolean;
}>;

const Root = styled('div', {
    label: 'PageContainerRoot',
})({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
});

const Card = styled('div', {
    label: 'PageContainerCard',
})({
    background: '#fff',
    padding: '40px 60px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    borderRadius: '4px',
    paddingLeft: '30px',
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    rowGap: '20px',
    columnGap: '10px',
    '@media only screen and (max-width: 600px)': {
        padding: '30px 40px',
        gridTemplateColumns: '1fr',
    },
});

const BackLink = styled(StyledLink, {
    label: 'PageContainerBackLink',
    shouldForwardProp: propName => propName !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
    display: 'flex',
    alignItems: 'center',
    gridColumn: '1 / span 2',
    gap: '10px',
    fontSize: '14px',
    ...(!visible && {
        visibility: 'hidden',
    }),
    '@media only screen and (max-width: 600px)': {
        gridColumn: '1',
    },
}));

const BackIcon = styled(Icon, {
    label: 'PageContainerBackIcon',
})({
    fontSize: '20px',
    alignSelf: 'center',
});

const Content = styled('div', {
    label: 'PageContainerContent',
    shouldForwardProp: propName => propName !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
    gridColumn: 2,
    ...(!visible && {
        visibility: 'hidden',
    }),
    '@media only screen and (max-width: 600px)': {
        gridColumn: '1',
    },
}));

const Loader = styled(CircularProgress, {
    label: 'PageContainerLoader',
})({
    position: 'absolute',
    top: '35%',
    left: '38%',
    transform: 'translate(-50%, 50%)',
});

export const PageContainer = ({
    title,
    children,
    backTitle,
    backHref,
    className,
    loaded = true,
}: PageContainerProps) => {
    return (
        <Root>
            <Head>
                <title>{`${title} | ALCS`}</title>
            </Head>
            {loaded && (
                <Typography fontSize="1.8rem" variant="h1" color="#fff">
                    {title}
                </Typography>
            )}

            <Card>
                <BackLink as={NextLink} href={backHref} visible={loaded}>
                    <BackIcon>keyboard_backspace_rounded_icon</BackIcon>
                    <span>{backTitle}</span>
                </BackLink>
                <Content className={className} visible={loaded}>
                    <ErrorBoundary fallback={<UnexpectedExceptionFallback />}>{children}</ErrorBoundary>
                </Content>
                {!loaded && <Loader size={100} />}
            </Card>
        </Root>
    );
};
