import React, { PropsWithChildren } from 'react';
import Draggable from 'react-draggable';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Icon,
    IconButton,
    Paper,
    PaperProps,
    Slide,
    SlideProps,
    styled,
    Toolbar,
    Typography,
} from '@mui/material';

import { PopupMessages } from './PopupMessages';
import { useGwt } from '../GwtContext';
import { GwtPopupContext } from '../GwtPopupContext';
import { PopupButton } from '../popups/PopupButton';
import PopupController from '../popups/PopupController';

export type MuiPopupProps<T> = PropsWithChildren<{
    controller: PopupController<T>;
    fullScreen?: boolean;
    title?: string;
    buttonBar?: PopupButton[];
    draggable?: boolean;
    isFullWidth?: boolean;
    disableBackdropClick?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    scrollType?: 'body' | 'paper';
    disableHeader?: boolean;
    removeContentPadding?: boolean;
    onClose?: () => void;
    classes?: DialogProps['classes'];
    disableDismiss?: boolean;
    HeaderComponent?: React.ComponentType<MuiPopupHeaderProps>;
    ActionsComponent?: React.ComponentType<MuiPopupActionsProps<T>>;
}>;

export const DraggablePaperComponent: React.FunctionComponent<PaperProps> = props => (
    <Draggable handle={'[class*="drag-enable"]'} cancel={'[class*="drag-disable"]'}>
        <Paper {...props} />
    </Draggable>
);

const SlideTransition = React.forwardRef<unknown, Omit<SlideProps, 'direction'>>(function SlideTransition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export type MuiPopupHeaderProps = {
    title?: string;
    fullScreen?: boolean;
};

const TitleRoot = styled('div', {
    label: 'TitleRoot',
})({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Title = styled(Typography, {
    label: 'Title',
    shouldForwardProp: propName => propName !== 'isFullScreen',
})<{ isFullScreen: boolean | undefined }>(({ isFullScreen }) => ({
    ...(isFullScreen && {
        flex: '1',
    }),
}));

const MuiPopupHeader: React.FunctionComponent<MuiPopupHeaderProps> = ({ title, fullScreen }) => {
    const { closeForm } = useGwt();
    return (
        <TitleRoot>
            <Title isFullScreen={fullScreen} variant="h5" as="div">
                {title}
            </Title>
            <IconButton
                color="inherit"
                aria-label="close"
                onClick={closeForm}
                edge={fullScreen ? 'start' : undefined}
                className="drag-disable"
            >
                <Icon>close</Icon>
            </IconButton>
        </TitleRoot>
    );
};

export type MuiPopupActionsProps<T> = {
    buttonBar: PopupButton[];
    controller: PopupController<T>;
    fullScreen?: boolean;
};

const MuiPopupActions = <T,>({ buttonBar, controller, fullScreen }: MuiPopupActionsProps<T>) => (
    <React.Fragment>
        {buttonBar.map((value: PopupButton, index: number) => (
            <Button
                color={fullScreen ? 'inherit' : 'primary'}
                key={index}
                onClick={e => value.onClick(e, controller)}
                disabled={value.disabled}
            >
                {value.title}
            </Button>
        ))}
    </React.Fragment>
);

const MuiPopup = <T,>({
    scrollType,
    controller,
    fullScreen,
    isFullWidth,
    maxWidth,
    disableBackdropClick,
    onClose,
    classes,
    buttonBar,
    ActionsComponent = MuiPopupActions,
    HeaderComponent = MuiPopupHeader,
    disableHeader,
    draggable,
    removeContentPadding,
    title,
    children,
    disableDismiss = false,
}: MuiPopupProps<T>) => {
    const data = controller.getData();
    return (
        <Dialog
            disablePortal
            disableEnforceFocus
            style={{ zIndex: 2 }}
            scroll={scrollType ?? 'body'}
            open={data.tempData.active}
            fullScreen={fullScreen}
            fullWidth={isFullWidth}
            maxWidth={maxWidth}
            onClose={(_, reason) => {
                if (reason === 'backdropClick' && disableBackdropClick) {
                    return;
                }
                if (!disableDismiss) {
                    controller.dismiss();
                }
                onClose?.();
            }}
            classes={classes}
            PaperComponent={draggable ? DraggablePaperComponent : undefined}
            TransitionComponent={fullScreen ? SlideTransition : undefined}
        >
            <GwtPopupContext
                dismiss={() => {
                    if (!disableDismiss) {
                        controller.dismiss();
                    }
                    onClose?.();
                }}
            >
                {!disableHeader &&
                    (fullScreen ? (
                        <AppBar position="relative">
                            <Toolbar>
                                <HeaderComponent fullScreen={fullScreen} title={title} />
                                {buttonBar && (
                                    <ActionsComponent
                                        buttonBar={buttonBar}
                                        controller={controller}
                                        fullScreen={fullScreen}
                                    />
                                )}
                            </Toolbar>
                        </AppBar>
                    ) : (
                        <DialogTitle
                            className="drag-enable"
                            // TODO: check this
                            // disableTypography
                            style={{ cursor: draggable ? 'move' : 'normal' }}
                        >
                            <HeaderComponent fullScreen={fullScreen} title={title} />
                        </DialogTitle>
                    ))}
                <DialogContent style={removeContentPadding ? { padding: 0 } : undefined}>
                    <PopupMessages />
                    {children}
                </DialogContent>
                {buttonBar && !fullScreen && (
                    <DialogActions>
                        <ActionsComponent buttonBar={buttonBar} controller={controller} fullScreen={fullScreen} />
                    </DialogActions>
                )}
            </GwtPopupContext>
        </Dialog>
    );
};

export default MuiPopup;
