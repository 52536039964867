/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 */

import { IGwtContext } from '@alcs/gwt';

export class ChangeListener<T> {
    private initialJsonValue = '';
    private currentJsonValue = '';

    public constructor(private gwtContext?: IGwtContext) {
        this.initialFormValues = this.initialFormValues.bind(this);
        this.modifiedFormValues = this.modifiedFormValues.bind(this);
    }

    private replacer(_key: string, value: unknown) {
        if (typeof value === 'number') {
            return value.toFixed(2);
        }

        /**
         * This replaces all empty strings with null.
         *
         * Service returns null for empty text fields, so if null has changed to empty string,
         * form values could not change
         */
        if (typeof value === 'string' && value === '') {
            return null;
        }

        return value;
    }

    public initialFormValues(values: T): T {
        this.initialJsonValue = JSON.stringify(values, this.replacer);
        this.currentJsonValue = JSON.stringify(values, this.replacer);
        this.gwtContext?.initialFormValues({ modified: false });
        return values;
    }

    public modifiedFormValues(values: T) {
        this.currentJsonValue = JSON.stringify(values, this.replacer);
        this.gwtContext?.modifiedFormValues({ modified: this.isModified() });
    }

    public isModified = (): boolean => this.currentJsonValue !== this.initialJsonValue;
}

export default ChangeListener;
